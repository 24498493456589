import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Host from '../../Components/Host';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useFetchQuery, useCreateMutation } from '../../services/cart';
import { useGetUserDataQuery } from '../../services/login';
import _, { isArray } from "lodash"
import Loader from '../../Components/Loader'
import CartPage from '../../Pages/CartPage';
import Button from '@mui/joy/Button';
import formatPrice from '../formatPrice';
import { useMediaQuery } from 'react-responsive'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

var moment = require('moment');

function AddToCart({ productData, productVarData, productFetching, cartButton, title, isLoading, isFetching, productNotAvailable }) {
  const { userId, userToken, cartToken, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()
  const [getQuantity, setQuantity] = useState(1);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 768px)' })

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const deliveryDaya = Number(productVarData?.estimated_delivery) + Number(userData?.contant?.delivery_days || 0)
  function getTimeline() {
    if (Number(deliveryDaya) === 1) {
      return 'Tomorrow'
    } else {
      return ''
    }
  }

  function getDeliverydays() {
    if (Number(deliveryDaya) > 0) {
      return <span style={{ fontWeight: 500 }}>{getTimeline()} {moment().add(Number(deliveryDaya), 'days').format('ddd Do MMM')}</span>
    } else {
      return <span style={{ fontWeight: 500 }}>{moment().add(7, 'days').format('dddd Do MMM YYYY')}</span>
    }
  }

  const getCart = useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const [createNew, createresult] = useCreateMutation()
  const findCart = getCart?.data?.cart?.find((l) => l.product_id === productData?.id)

  const [getcartDrawer, cartDrawer] = useState();
  useEffect(() => {
    if (createresult?.isSuccess) {
      if (document.body.dir === 'rtl') {
        cartDrawer('left', true)
      } else {
        cartDrawer('right', true)
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: productData?.prices?.currency,
          value: Number(productData?.prices?.value),
          items: [
            {
              item_id: productData?.id,
              item_name: productData?.title,
              item_brand: productData?.brand,
              item_category: productData?.category,
              price: Number(productData?.prices?.value),
              quantity: productData?.quantity
            }
          ]
        }
      });
    }
  }, [createresult])


  let thisFeatures = productData?.features
  if (productVarData?.features) {
    thisFeatures = productVarData?.features
  }

  const numbers = Array.from({ length: 20 }, (_, i) => i + 1);
  const tabbyCurrencies = ['SAR', 'AED', 'BHD', 'QAR', 'KWD']

  return (
    <div className="purchase-info-sub-card purchase-information-card">
      <CartPage getcartDrawer={getcartDrawer} cartDraweraa={cartDrawer} />

      {!isMobcreen &&
        <div className='pg-price' style={{ color: '#000000c4', fontSize: '1.4rem', lineHeight: 1.1 }}>
          {productFetching ?
            <Skeleton variant="rect" style={{ height: '16px', width: '100%' }} /> :
            <>
              {Number(productData?.prices?.value) > 0 ?
                <h5 style={{ fontSize: '1.4rem' }}>
                  <span>
                    {formatPrice(productData?.prices?.value, productData?.prices?.currency ? productData?.prices?.currency : userData?.contant?.currency, 0)}
                  </span><br />
                  {productData?.prices?.deal_value &&
                    <span className='alert alert-danger'
                      style={{ background: '#fff0', border: 'none', padding: 0, margin: 0, fontSize: '1rem' }}>
                      <span style={{ textDecoration: 'line-through', fontWeight: 400 }}>
                        {formatPrice(productData?.prices?.price, productData?.prices?.currency ? productData?.prices?.currency : userData?.contant?.currency, 0)}
                      </span> <span style={{ fontWeight: 600 }}>50% Off</span>
                    </span>
                  }
                </h5> : ''
              }
            </>
          }
        </div>
      }

      {isLoading &&
        <div style={{ marginTop: '10px' }}>
          <Skeleton variant="rect" style={{ height: '16px', width: '50%' }} />
          <Skeleton variant="rect" style={{ height: '16px', width: '100%' }} />
        </div>
      }

      {!productNotAvailable &&
        <div className='atc-button'>
          <div className='atc-button-card'>
            <Dropdown>
              <MenuButton
                className="atc-qty-button"
                size='sm'
                variant='outlined'
              >
                <div className='atc-qty-button-item'>
                  <div>{t('quantity')}: <stong>{getQuantity}</stong></div>
                  <ExpandMoreIcon className="expand-style mobile-hide" />
                </div>
              </MenuButton>
              <Menu className="qty-dropdown" style={{ minWidth: '200px', maxWidth: '100%' }}>
                {numbers.map(num => (
                  <MenuItem
                    key={num}
                    onClick={(() => setQuantity(num))}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ margin: '0px 10px' }}>{num}</div>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </Dropdown>
          </div>
          {userData?.contant?.currency &&
            <div className='atc-button-item'>
              {[document.body.dir === 'rtl' ? 'left' : 'right'].map((anchor) => (
                <IconButton
                  style={{ width: 'auto', height: '40px', background: Host?.bgColor, color: Host?.buttonColor, padding: '6px 12px', textTransform: 'capitalize', borderRadius: '4px', fontSize: '15px', width: '100%' }}
                  disabled={cartButton}
                  variant="contained"
                  key={anchor}
                  onClick={(() => findCart ? cartDrawer(anchor, true) :
                    createNew({
                      id: productData?.id,
                      asin: productData?.asin,
                      pid: productData?.pid,
                      country: productData?.country,
                      quantity: getQuantity,
                      cartToken: cartToken,
                      userId: userId,
                      countryCode: countryCode,
                      price: productData?.prices?.price,
                      store_id: productData?.store_id,
                      inventory: productData?.inventory
                    }))}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {createresult?.isLoading ? <Loader /> :
                      <>
                        <img src={`/assets/svgs/cart.svg`} style={{ width: '18px' }} />
                        &nbsp;
                      </>
                    }
                    <span>
                      {findCart ? t('added_to_cart') : t('add_to_cart')}
                    </span>
                  </div>
                </IconButton>
              ))}
            </div>
          }
        </div>
      }

      {!isMobcreen &&
        <div className='pg-section-aatc' style={{ padding: '10px 0px 0px 0px', margin: '0px 3px' }}>
          {!isLoading &&
            <>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontSize: '12px' }}>
                <span>{t('estimated_delivery')}</span><br />{getDeliverydays()}
              </div>
            </>
          }
        </div>
      }

      {!isMobcreen &&
        <>
          {tabbyCurrencies?.includes(userData?.contant?.currency) &&
            <div className='product-brand' style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '0px 3px', borderRadius: '4px', }}>
              <div style={{ fontSize: '13px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', background: '#f5f5f5', padding: '10px', margiTop: '15px', borderRadius: '4px', marginTop: '15px' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>Split in 4 payments of &nbsp;</span>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {productFetching ? <Skeleton variant="rect" style={{ height: '12px', width: '50px' }} /> :
                    <strong>
                      {formatPrice(Number(productData?.prices?.value) / 4, productData?.prices?.currency ? productData?.prices?.currency : userData?.contant?.currency, 0)}.&nbsp;</strong>
                  }
                </span>
                <span style={{ display: 'flex', alignItems: 'center' }}>No interest with &nbsp;</span>
                <span style={{ display: 'flex', alignItems: 'center' }}><img src='/tabby-logo-1.png' style={{ width: '40px' }} /></span>
              </div>
            </div>
          }
        </>
      }


    </div>
  );
}

export default AddToCart;
