import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { useGetProductQuery, useSimilarProductQuery, useGetVarProductQuery } from '../../services/product';
import { useGetRelatedQuery } from '../../services/homepage';
import ProductImages from '../../Components/ProductImages';
import RelatedProducts from '../../Components/RelatedProducts';
import SimilarProducts from '../../Components/SimilarProducts';
import ProductDetails from '../../Components/ProductDetails';
import ProductFeatures from '../../Components/ProductFeatures';
import AddToCart from '../../Components/AddToCart';
import { useMediaQuery } from 'react-responsive'
import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import TagManager from 'react-gtm-module'
import HelmetComponent from '../../Components/HelmetComponent';
import { addViewedProducts, addLocalProducts } from "../../Reducers/authReducer";
import ViewedProducts from '../../Components/ViewedProducts';
import { Helmet } from 'react-helmet-async';
import Host from '../../Components/Host';
import { notification } from 'antd';
import "antd/dist/antd.min.css";

// const tagManagerArgs = {
//   gtmId: 'GTM-P2P68757'
// }
// TagManager.initialize(tagManagerArgs)

const storIds = [1, 3]

function Product() {
  const { userId, userToken, userEmail, userName, cartToken, languageCode, countryCode, viewedProducts, localProducts } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { storeId, id, title } = useParams();
  const variant = new URLSearchParams(location.search).get('variant') || ''

  const country = new URLSearchParams(location.search).get('country') || ''
  const productInfo = useGetProductQuery({ id: id, store_id: storeId, countryCode: countryCode, variant: variant, country: country }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const productData = productInfo?.data?.product

  function productTitle(title) {
    const thitilte = title?.split(" ").slice(0, 100).join(" ")
    return thitilte?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s]/gi, ' ')?.replace(/ /g, '-')
  }
  useEffect(() => {
    if (!productInfo?.isLoading) {
      if (!productInfo?.isFetching) {
        if (storIds?.includes(Number(productData?.store_id))) {
          if (id !== productData?.pid) {
            history.replace(`/product/${productData?.store_id}/${productData?.pid}/${productTitle(productData?.title)}`);
          }
        }
      }
    }
  }, [productData?.pid, id, history])

  const productVarInfo = useGetVarProductQuery({ id: id, store_id: storeId, countryCode: countryCode, variant: variant }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const productVarData = productVarInfo?.data?.variant_product

  const similarTitle = title?.substring(0, 20)?.replace(/-/g, ' ')
  const similarInfo = useSimilarProductQuery({
    id: id,
    store_id: storeId,
    title: similarTitle,
    countryCode: countryCode,
    variant: variant
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const similarProduct = similarInfo?.data?.similar_products

  const relatedData = useGetRelatedQuery({ countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 768px)' })

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  function cartButton() {
    if (productData?.inventory && Number(productData?.qty) > 0) {
      if (productInfo?.isLoading) {
        return true
      } else if (!productData?.prices?.value) {
        return true
      } else if (Number(productData?.prices?.value) === 0) {
        return true
      } else if (productInfo?.isFetching) {
        return true
      } else if (productVarInfo?.isFetching) {
        return true
      } else if (productVarInfo?.isLoading) {
        return true
      } else {
        return false
      }
    } else {
      if (productInfo?.isLoading) {
        return true
      } else if (!productData?.prices?.value) {
        return true
      } else if (Number(productData?.prices?.value) === 0) {
        return true
      } else if (!productData?.in_stock) {
        return true
      } else if (Number(productData?.quantity) === 0) {
        return true
      } else if (productInfo?.isFetching) {
        return true
      } else if (productVarInfo?.isFetching) {
        return true
      } else if (productVarInfo?.isLoading) {
        return true
      } else {
        return false
      }
    }
  }

  function productNotAvailable() {
    if (productData?.inventory && Number(productData?.qty) > 0) {
      if (!productData?.prices?.value) {
        return true
      } else if (Number(productData?.prices?.value) === 0) {
        return true
      } else {
        return false
      }
    } else {
      if (!productData?.prices?.value) {
        return true
      } else if (Number(productData?.prices?.value) === 0) {
        return true
      } else if (!productData?.in_stock) {
        return true
      } else if (Number(productData?.quantity) === 0) {
        return true
      } else {
        return false
      }
    }
  }

  function productFetching() {
    if (productInfo?.isLoading) {
      return true
    } else if (productInfo?.isFetching) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (!productFetching()) {
    }
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [productInfo])

  function getProductDetails() {
    return (
      <ProductDetails
        productVarData={productVarData}
        isVarLoading={productVarInfo?.isLoading}
        isVarFetching={productVarInfo?.isFetching}
        productData={productData}
        productFetching={productFetching()}
        isLoading={productInfo?.isLoading}
        isFetching={productInfo?.isFetching}
      />
    )
  }

  function getProductFeatures() {
    return (
      <ProductFeatures
        productData={productData}
        productVarData={productVarData}
        productFetching={productFetching()}
        cartButton={cartButton()}
        asin={productData?.asin}
        title={title}
        id={id}
        variant={variant}
        isLoading={productInfo?.isLoading}
        isFetching={productInfo?.isFetching}
        isVarLoading={productVarInfo?.isLoading}
        isVarFetching={productVarInfo?.isFetching}
        productNotAvailable={productNotAvailable()}
      />
    )
  }

  const [getAlertDialogue, setAlertDialogue] = React.useState(false);

  useEffect(() => {
    if (productData?.inventory) {
      setAlertDialogue(false)
    } else {
      if (!productFetching()) {
        if (!productData) {
          setAlertDialogue(true)
        } else {
          if (productData?.status) {
            setAlertDialogue(true)
          } else {
            setAlertDialogue(false)
          }
        }
      }
      if (productFetching()) {
        setAlertDialogue(false)
      }
    }
  }, [productInfo, productData, id, variant, title])


  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        currency: productData?.prices?.currency,
        value: Number(productData?.prices?.value),
        items: [
          {
            item_id: productData?.id,
            item_name: productData?.title,
            item_brand: productData?.brand,
            item_category: productData?.category,
            price: Number(productData?.prices?.value),
            quantity: productData?.quantity
          }
        ]
      }
    });
  }, [productInfo, productData, id, variant, title]);

  const productSubstringTitle = title ? title?.substring(0, 80) : productData?.title?.substring(0, 80)

  const productDetails = productData?.details

  function checkDetails(details) {
    if (details === '<p></p>') {
      return false
    } else if (!details) {
      return false
    } else {
      return true
    }
  }

  const ConvertTextToImages = ({ text }) => {
    const replaceUrlsWithImages = (inputText) => {
      const urlRegex = /(https?:\/\/[^\s]+(?:\.jpg|\.png|\.gif))/g;
      return inputText.replace(
        urlRegex,
        (url) => `<img src="${url}" style="max-width: 100%; height: auto; width: 100%;" />`
      );
    };
    const processedText = replaceUrlsWithImages(text);
    return (
      <div
        style={{ fontSize: '13px', color: '#38393a' }}
        dangerouslySetInnerHTML={{ __html: processedText }}
      />
    );
  };


  useEffect(() => {
    const getLocalProducts = () => {
      const products = localStorage.getItem('localProducts');
      return products ? JSON.parse(products) : [];
    };
    const addOrReplaceProduct = async (newProduct) => {
      if (newProduct) {
        let products = await getLocalProducts();
        const index = await products?.findIndex((product) => product.id === newProduct.id);
        if (index !== -1) {
          products[index] = newProduct;
        } else {
          await products?.push(newProduct);
        }
        if (products?.length > 10) {
          await products?.shift();
        }
        if (newProduct?.image) {
          dispatch(addLocalProducts({ localProducts: products }));
        }
      }
    };
    const newProduct = {
      id: productData?.id,
      pid: productData?.pid,
      store_id: productData?.store_id,
      title: productData?.title,
      image: productData?.image,
    };
    addOrReplaceProduct(newProduct);
  }, [productData])

  const itemCondition = productData?.condition === 'used' ? 'https://schema.org/UsedCondition' : 'https://schema.org/NewCondition';
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": productData?.title,
    "description": productData?.details,
    "sku": productData?.id,
    "image": productData?.image,
    "offers": {
      "@type": "Offer",
      "priceCurrency": productData?.prices?.currency,
      "price": productData?.prices?.value,
      "itemCondition": itemCondition,
      "availability": productData?.in_stock ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
    }
  };

  const headerTitle = `${productSubstringTitle && productSubstringTitle?.replace(/-/g, " ")?.replace(/[^\w\s.,'/:]/gi, ' ')}`

  const [api, contextHolder] = notification.useNotification();
  React.useEffect(() => {
    if (productInfo?.error?.originalStatus === 429) {
      api['error']({
        message: productInfo?.error?.data,
        showProgress: true,
        pauseOnHover: false,
        duration: 5,
        placement: 'top'
      });
    }
  }, [productInfo]);


  const storeIds = [1, 3]

  return (
    <>
      <Helmet>
        <title>{`${headerTitle ? headerTitle : ''} ${headerTitle && '|'} ${Host?.metaTitle}`}</title>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {contextHolder}
      {/* <HelmetComponent title={`${productSubstringTitle && productSubstringTitle?.replace(/-/g, " ")?.replace(/[^\w\s.,'/:]/gi, ' ')}`} /> */}
      <div className="product-page">

      <div className="container">
          <div className="col-md-12" style={{ padding: '5px' }}>
            <Link
              to="/store/deals"
              style={{ position: 'relative' }}>
              {isMobcreen ?
                <img
                  src='https://cdn.ebtida.com/1743079511189-goshopright-mob-final.gif'
                  style={{ width: '100%', borderRadius: '6px', filter: 'saturate(0.7)' }} /> :
                <img
                  src='https://cdn.ebtida.com/1743079490666-goshopright-web-final.gif'
                  style={{ width: '100%', borderRadius: '6px', filter: 'saturate(0.7)' }} />
              }
            </Link>
          </div>
        </div>



        <div className="container purchase-container">
          <div className="row product-section" style={{ position: 'relative' }}>
            {getAlertDialogue &&
              <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-md-4" style={{ textAlign: 'center' }}>
                  <br />
                  <div className="form-group">
                    <div className='alert alert-secondary' style={{ display: 'flex', alignItems: 'center', textAlign: 'start', fontSize: '1rem', fontWeight: 400 }}>
                      <InfoOutlinedIcon style={{ fontSize: '1.5rem' }} />
                      <span style={{ margin: '0px 10px' }}>The product is not available!</span>
                    </div>
                    <div className="col-md-12" style={{ textAlign: 'start', padding: 0 }}>
                      <div className="form-group">
                        <Button
                          variant='outlined'
                          style={fontFamily}
                          component={Link}
                          to={`/search/${title?.substring(0, 30)?.replace(/-/g, " ")}`}
                        >
                          <div style={{ textTransform: 'capitalize', fontWeight: 400 }}>Search for similar</div>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {!getAlertDialogue &&
              <>

                <div className="col-md-10 row-col-p" style={{ padding: 0 }}>
                  <div className="row" style={{ justifyContent: 'center' }}>

                    <div className="col-md-6 row-col-p" style={{ padding: 0 }}>
                      <div className='product-image-view'>
                        <div className="purchase-form-card product-image-main" style={{ position: 'relative' }}>
                          <ProductImages
                            productVarData={productVarData}
                            isVarLoading={productVarInfo?.isLoading}
                            productData={productData}
                            productFetching={productFetching()}
                            isLoading={productInfo?.isLoading}
                            isFetching={productInfo?.isFetching}
                          />
                        </div>
                      </div>
                      {!isMobcreen &&
                        <div className="specification-section">
                          {getProductDetails()}
                        </div>
                      }
                    </div>

                    <div className="col-md-6 row-col-p">

                      <div className="purchase-info-card bb">
                        {getProductFeatures()}
                      </div>

                      {isMobcreen &&
                        <div className="specification-section">
                          {getProductDetails()}
                        </div>
                      }
                    </div>

                  </div>

                  <div className="row" style={{ justifyContent: 'center', marginTop: '15px' }}>
                    <div className="col-md-12 row-col-p" style={{ padding: 0 }}>
                      {productData?.specifications?.length > 0 && <h5 className='cardTitle'>{t('specifications')}</h5>}
                      {productData?.specifications &&
                        <div className='spec-table-over'>
                          <table className='table' style={{ width: '100%', fontSize: '13px' }}>
                            <tbody>
                              {Array.isArray(productData?.specifications) ? (
                                productData?.specifications?.map((item, index) => {
                                  const keyName = item.key?.toLowerCase();
                                  if (keyName === 'customer reviews' || keyName === 'asin' || keyName === 'best sellers rank') return null;
                                  return (
                                    <>
                                      {storeIds?.includes(Number(productData?.store_id)) ?
                                        <tr key={index}>
                                          <td style={{ fontWeight: 600, width: '50%', border: 'none' }}>{item.key}</td>
                                          <td style={{ color: '#6b6b6b', width: '50%', border: 'none' }}>{item.value}</td>
                                        </tr> :
                                        <>
                                          {Object.entries(item || []).map(([key, value], index) => {
                                            const keyName = key?.toLowerCase();
                                            if (keyName === 'customer reviews' || keyName === 'asin' || keyName === 'best sellers rank') return null;
                                            return (
                                              <tr key={index}>
                                                <td style={{ fontWeight: 600, width: '50%', border: 'none' }}>{key?.toString()}</td>
                                                <td style={{ color: '#6b6b6b', width: '50%', border: 'none' }}>{value?.toString()}</td>
                                              </tr>
                                            )
                                          }
                                          )}
                                        </>
                                      }
                                    </>
                                  )
                                })
                              ) : productData?.specifications && typeof productData?.specifications === 'object' ? (
                                Object.entries(productData?.specifications)?.map(([key, value], index) => {
                                  const keyName = key?.toLowerCase();
                                  if (keyName === 'customer reviews' || keyName === 'asin' || keyName === 'best sellers rank') return null;
                                  return (
                                    <tr key={index}>
                                      <td style={{ fontWeight: 600, width: '50%', border: 'none' }}>{key}</td>
                                      <td style={{ color: '#6b6b6b', width: '50%', border: 'none' }}>{value}</td>
                                    </tr>
                                  )
                                }
                                )
                              ) : ''}
                            </tbody>
                          </table>
                        </div>
                      }
                    </div>
                  </div>
                </div>


                <div className="col-md-2 row-col-p" style={{ padding: '0' }}>
                  <AddToCart
                    productData={productData}
                    productVarData={productVarData}
                    productFetching={productFetching()}
                    cartButton={cartButton()}
                    asin={productData?.asin}
                    title={title}
                    id={id}
                    variant={variant}
                    isLoading={productInfo?.isLoading}
                    isFetching={productInfo?.isFetching}
                    isVarLoading={productVarInfo?.isLoading}
                    isVarFetching={productVarInfo?.isFetching}
                    productNotAvailable={productNotAvailable()}
                  />
                </div>


              </>
            }
          </div>

          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-12" style={{ padding: 0 }}>
              {!productInfo?.isLoading &&
                Number(productData?.store_id) === 9 &&
                <>
                  <h5 className='cardTitle'>{t('product_details')}</h5>
                  {checkDetails(productData?.details) &&
                    <ConvertTextToImages text={productDetails} />
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>


      {localProducts?.length > 1 &&
        <>
          <div className='hp-style'>
            <ViewedProducts
              relatedData={localProducts}
              productId={productData?.id}
            />
          </div>
          <br />
        </>
      }

      {similarProduct?.length > 1 &&
        <>
          <div className='hp-style'>
            <SimilarProducts
              relatedData={similarProduct}
              isLoading={similarInfo?.isLoading}
            />
          </div>
          <br />
        </>
      }

      <div className='hp-style'>
        <RelatedProducts
          relatedData={relatedData?.data?.related}
          isLoading={relatedData?.isLoading}
        />
      </div>
    </>
  );
}

export default Product;
