import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const storeIds = [1, 3]

function ProductDetails({ productData, productFetching, isLoading, isFetching, productVarData, isVarLoading }) {
  const { languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()

  const [showDetails, setShowDetails] = useState(false);
  const productDetails = showDetails ? productData?.details : productData?.details?.substring(0, 250)

  function checkDetails() {
    if (productData?.details === '<p></p>') {
      return false
    } else if (!productData?.details) {
      return false
    } else {
      return true
    }
  }

  function createImageTags(inputString) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let htmlString = inputString.replace(urlRegex, (url) => {
      if (/\.(png|jpg|jpeg|gif|bmp)$/i.test(url)) {
        return `
        <div style="display: flex; justify-content: center; padding: 10px 10px 10px 10px;">
        <img src="${url}" alt="Image" style="width: 200px; object-fit: contain" />
        </div>
        `;
      } else {
        return url;
      }
    });
    return htmlString;
  }

  const productWordsCount = productData?.details?.trim() === "" ? 0 : productData?.details?.trim().split(/\s+/)?.length;

  return (
    <>
      {isLoading ?
        <Skeleton variant="rect" height={'15px'} width={'50%'} /> :
        <>
          {checkDetails() &&
            <>
              {storeIds?.includes(Number(productData?.store_id)) ?
                <>
                  <h5 className='cardTitle'>{t('product_details')}</h5>
                  <div style={{ color: '#38393a', fontSize: '13px', wordBreak: 'auto-phrase' }} dangerouslySetInnerHTML={{
                    __html: productDetails?.replace(/�/g, "'")
                  }}>
                  </div>
                  {productWordsCount > 200 &&
                    <div
                      onClick={(() => setShowDetails(showDetails ? false : true))}
                      style={{ fontSize: '0.85rem', cursor: 'pointer', color: '#4e93d9', fontWeight: 400 }}>
                      {showDetails ? t('show_less') : t('show_more')}
                    </div>
                  }
                </>
                :
                <>
                  {Number(productData?.store_id) !== 9 &&
                    <>
                      <h5 className='cardTitle'>{t('product_details')}</h5>
                      <div style={{ color: '#38393a', fontSize: '13px', wordBreak: 'auto-phrase' }} dangerouslySetInnerHTML={{
                        __html: createImageTags(productDetails?.replace(/�/g, "'"))
                      }}>
                      </div>
                      {productWordsCount > 200 &&
                        <div
                          onClick={(() => setShowDetails(showDetails ? false : true))}
                          style={{ fontSize: '0.85rem', cursor: 'pointer', color: '#4e93d9', fontWeight: 400 }}>
                          {showDetails ? t('show_less') : t('show_more')}
                        </div>
                      }
                    </>
                  }
                </>
              }
            </>
          }
        </>
      }
    </>
  );
}

export default ProductDetails;
