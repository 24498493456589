import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Carousel from 'react-bootstrap/Carousel'
import Host from '../../Components/Host';
import HomeData from '../../Components/HomeData';
import { useMediaQuery } from 'react-responsive'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function HomeCarousel() {
  const { userId, userToken, userEmail, languageCode, countryCode, cartToken } = useSelector((state) => state.auth);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 768px)' })

  const thisclass = 'hp-carouselgo'

  return (
    <>
      {isMobcreen ?
        <Carousel className={`hp-carousel ${thisclass}`} fade style={{ padding: '5px' }}>
          {HomeData?.carousel?.map((item, i) =>
            <Carousel.Item key={i} interval={5500} style={{ borderRadius: '8px', objectFit: 'cover' }}>
              <Link to={`${item.link}`}>
                <img
                  loading="lazy"
                  className="Dashboard-board-img"
                  src={item?.image}
                  style={{ borderRadius: '8px', objectFit: 'cover' }}
                />
              </Link>
            </Carousel.Item>
          )}
        </Carousel>
        :
        <Carousel className={`hp-carousel ${thisclass}`} fade style={{ padding: '5px' }}>
          {Host?.bannesImages?.map((item, i) =>
            <Carousel.Item key={i} interval={5500} style={{ borderRadius: '8px', objectFit: 'cover' }}>
              <Link to={`${item.link}`}>
                <img
                  loading="lazy"
                  className="Dashboard-board-img"
                  src={item?.image}
                  style={{ borderRadius: '8px',  objectFit: 'cover' }}
                />
              </Link>
            </Carousel.Item>
          )}
        </Carousel>
      }
    </>
  );
}

export default HomeCarousel;
