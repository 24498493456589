import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Host from '../../Components/Host';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useFetchQuery, useCreateMutation } from '../../services/cart';
import { useGetUserDataQuery } from '../../services/login';
import _, { isArray, pad } from "lodash"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from 'react-bootstrap'
import Rating from '@mui/material/Rating';
import Variation from './Variation';
import Button from '@mui/joy/Button';
import formatPrice from '../formatPrice';
import ProductCountries from '../Header/ProductCountries';
import { useMediaQuery } from 'react-responsive'
var moment = require('moment');

function ProductFeatures({ productData, productVarData, productFetching, cartButton, asin, title, id, variant, isLoading, isFetching, productNotAvailable, isVarLoading, isVarFetching }) {
  const { userId, userToken, cartToken, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()
  const [getQuantity, setQuantity] = useState(1);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 768px)' })
  const refetchedData = {
    is_refetch: productVarData?.pid ? true : false,
    in_stock: productVarData?.in_stock,
    value: productVarData?.price,
    pid: productVarData?.pid,
  }

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  const [getFeaturesLength, setFeaturesLength] = useState(false);
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const deliveryDaya = Number(productVarData?.estimated_delivery) + Number(userData?.contant?.delivery_days || 0)
  function getTimeline() {
    if (Number(deliveryDaya) === 1) {
      return 'Tomorrow'
    } else {
      return ''
    }
  }

  function getDeliverydays() {
    if (Number(deliveryDaya) > 0) {
      return <span style={{ fontWeight: 500 }}>{getTimeline()} {moment().add(Number(deliveryDaya), 'days').format('ddd Do MMM')}</span>
    } else {
      return <span style={{ fontWeight: 500 }}>{moment().add(7, 'days').format('dddd Do MMM YYYY')}</span>
    }
  }

  const getCart = useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const [createNew, createresult] = useCreateMutation()
  const findCart = getCart?.data?.cart?.find((l) => l.product_id === productData?.id)
  const [geturnModal, returnModal] = useState(false);

  function returnPolicyModal() {
    returnModal(true)
  }

  function closeModal() {
    returnModal(false)
  }



  let thisFeatures = productData?.features
  if (productVarData?.features) {
    thisFeatures = productVarData?.features
  }


  const thisProTitle = title?.replace(/-/g, " ")?.replace(/[^\w\s.,'/:]/gi, ' ') || productData?.title?.replace(/[^\w\s.,'/:]/gi, ' ')
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => { setIsExpanded((prev) => !prev) };
  const truncatedTitle = thisProTitle?.split(" ").length > 100 ? thisProTitle?.split(" ").slice(0, 100).join(" ") + "..." : thisProTitle;


  function localViewStore() {
    return (
      <div
        className='button-margin-dm'
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button
          size='sm'
          variant='outlined'
          color='primary'
          component={Link}
          to={`/store/${productData?.store_id}`}
          style={{ fontSize: '0.85rem', fontWeight: 400, minHeight: '28px', maxHeight: '28px', border: '1px solid rgb(213, 217, 217)', color: '#333638', borderRadius: '4px' }}
        >
          {t('view_store')}
        </Button>
      </div>
    )
  }

  const numbersQnty = [1, 2, 3, 4]
  function getStockQnty(quantity) {
    if (productData?.store_id != 3) {
      return (
        ''
        // <span style={{ color: 'tomato' }}>Only {quantity} stock left</span>
      )
    } else if (Number(quantity) === 0) {
      return (
        <div style={{ padding: '5px 0px 5px 0px', display: 'flex', alignItems: 'center', }}>
          <div>{t('availability')}:</div>
          <div className='value' style={{ margin: '0 10px' }}>
            <span style={{ color: 'tomato' }}>Out of stock</span>
          </div>
        </div>
      )
    } else {
      return (
        ''
        // <span style={{ color: '#02732f' }}>
        //   In Stock <CheckOutlinedIcon style={{ fontSize: '15px', color: '#02732f' }} />
        // </span>
      )
    }
  }

  const tabbyCurrencies = ['SAR', 'AED', 'BHD', 'QAR', 'KWD']

  return (
    <div className="purchase-info-sub-card">
      <Modal style={fontFamily} className="refund-modal" show={geturnModal} onHide={closeModal} animation={true}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <p id="#return" style={{ marginBottom: '11px' }}><span style={{ fontSize: '11pt' }}><span style={{ lineHeight: '107%' }}><strong>Returns, Warranty and Refunds</strong></span></span></p>
                <ol style={{ padding: '10px' }}>
                  <li style={{ marginLeft: '8px' }}><span style={{ fontSize: '11pt' }}><span style={{ lineHeight: '107%' }}><span>If an item is defective or damaged on arrival. Contact us to get it returned or refunded immediately within 3 days of receiving the item. </span></span></span></li>
                  <li style={{ marginLeft: '8px' }}><span style={{ fontSize: '11pt' }}><span style={{ lineHeight: '107%' }}><span>Warranty coverage of an item if provided by the manufacturer is provided for 1 year and we shall cover expenses of shipping an item back (unless it is a customized order).</span></span></span></li>
                </ol>
                <Link to="/returns-and-refund" >Learn More</Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {!isLoading &&
        <>
          {!productData &&
            <div
              style={{ background: '#fff0', border: 'none', padding: 0, margin: 0 }}
              className='alert alert-danger'
            >
              {t('product_not_available')}
            </div>
          }
        </>
      }


      {isMobcreen &&
        <div className='pg-price' style={{ color: '#000000c4', fontSize: '1.4rem', margin: '0px 3px', lineHeight: 1.1 }}>
          {productFetching ?
            <Skeleton variant="rect" style={{ height: '16px', width: '12%' }} /> :
            <>
              <h5 style={{ fontSize: '1.4rem' }}>
                <span>
                  {formatPrice(productData?.prices?.value, productData?.prices?.currency ? productData?.prices?.currency : userData?.contant?.currency, 0)}
                </span>&nbsp;
                {productData?.prices?.deal_value &&
                  <span className='alert alert-danger'
                    style={{ background: '#fff0', border: 'none', padding: 0, margin: 0, fontSize: '1rem' }}>
                    <span style={{ textDecoration: 'line-through', fontWeight: 400 }}>
                      {formatPrice(productData?.prices?.price, productData?.prices?.currency ? productData?.prices?.currency : userData?.contant?.currency, 0)}
                    </span> <span style={{ fontWeight: 600 }}>50% Off</span>
                  </span>
                }
              </h5>
            </>
          }
        </div>
      }

      <h1 className='product-title' style={{ margin: '0px 3px', wordBreak: 'auto-phrase', paddingTop: 0 }}>
        {isExpanded ? thisProTitle : truncatedTitle}
        {thisProTitle?.split(" ").length > 100 && (
          <span
            onClick={(toggleExpanded)}
            style={{ fontSize: '0.9rem', cursor: 'pointer', color: '#5b7627', fontWeight: 500 }}>
            {isExpanded ? "Show Less" : "See More"}
          </span>
        )}
      </h1>

      {productData?.brand &&
        <div className='product-brand' style={{ fontSize: '14px', paddingBottom: 0, margin: '0px 3px' }}>
          <span className='value'>
            <Link
              className="badge badge-light"
              style={{ fontSize: '0.8rem', padding: 0, background: '#fff0' }} to={`/search/${productData?.brand?.toLowerCase()}`}>
              {productData?.brand?.toLowerCase()}
            </Link>
          </span>
        </div>
      }
      {isLoading ?
        <Skeleton variant="rect" style={{ height: '15px', width: '30%', margin: '0px 3px' }} /> :
        <>
          <div className='product-brand pp-mblock' style={{ fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px', flexWrap: 'wrap' }}>
            {productData?.condition &&
              <div>
                <span style={{ fontWeight: 500 }}>{t('condition')}: &nbsp;</span>
                <span className='value'>{productData?.condition?.toLowerCase()} </span>
                &nbsp;
              </div>
            }
            {productData?.rating && Number(productData?.rating) > 0 ?
              <div className='pp-mmargin' style={{ display: 'flex', alignItems: 'center', direction: 'ltr' }}>
                <span className='value'>{Number(productData?.rating) > 5 ? 5 : productData?.rating}</span>
                <Rating
                  style={{ color: '#fe7f4a', fontSize: '1rem', margin: '0px 2px', marginTop: '-1.5px' }}
                  name="read-only" value={Number(productData?.rating) > 5 ? 5 : productData?.rating} precision={0.1} readOnly />
                &nbsp;
              </div> : ''
            }
            <div
              className='pp-mmargin'
              onClick={(() => returnPolicyModal())}
              style={{ display: 'flex', alignItems: 'center', border: 'none', cursor: 'pointer' }}>
              <span className='value' style={{ textDecoration: 'underline' }}>{Host?.domainName} {t('protect')}</span>
              <InfoOutlinedIcon style={{ fontSize: '1rem', margin: '0px 2px', color: '#8a8a8a' }} />
            </div>
          </div>
        </>
      }

      {isLoading ?
        <Skeleton variant="rect" style={{ height: '15px', width: '30%', margin: '0px 3px' }} /> :
        <>
          <div className='product-brand pp-mblock'
            style={{ fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px', padding: 0 }}>
            <div className='product-brand pp-mmargin'>
              <div style={{ fontWeight: 500, fontSize: '14px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {productFetching ?
                  <Skeleton variant="rect" style={{ height: '15px', width: '71px' }} /> :
                  <>
                    {productNotAvailable ?
                      <>
                        <div style={{ padding: '5px 0px 5px 0px', display: 'flex', alignItems: 'center', }}>
                          <div>{t('availability')}:</div>
                          <div className='value' style={{ margin: '0 10px' }}>
                            <div style={{ color: 'tomato', fontSize: '1rem', textTransform: 'none' }}>
                              {productData?.variants ? t('currently_not_available') : t('out_of_stock')}
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        {getStockQnty(productData?.inventory && Number(productData?.qty) > 0 ? productData?.qty : productData?.quantity)}
                      </>
                    }
                  </>
                }

              </div>
            </div>
          </div>
        </>
      }

      <Variation productData={productData} productVarData={productVarData} isLoading={isLoading} isFetching={isFetching} id={id} variant={variant} isVarLoading={isVarLoading} isVarFetching={isVarFetching} />

      <div className='pg-section-aatc' style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '10px 0px 0px 0px', margin: '0px 3px' }}>

        {!isLoading &&
          <>
            <div
              className='alert alert-light pp-tag-style'
              style={{ borderRadius: 0, borderRadius: '4px', border: '1px solid rgb(213, 217, 217)', padding: '0px 10px', minHeight: '28px', height: '28px', background: '#fcfcfc', marginBottom: '0px' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.85rem', color: '#333638' }}>
                <div>{t('imported_from')}</div>
                <div style={{ display: 'flex', alignItems: 'center', margin: '0 5px' }}>
                  <img
                    style={{ width: '22px', objectFit: 'contain' }}
                    loading="lazy"
                    src={`https://flagcdn.com/w20/${productData?.country?.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${productData?.country?.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  <span style={{ color: '#333638', fontSize: '0.85rem', margin: '0 5px' }}>{ProductCountries(productData?.country)}</span>
                </div>
              </div>
            </div>

            {productData?.store_id != 3 &&
              localViewStore()
            }
          </>
        }
      </div>

      {isMobcreen &&
        <div className='pg-section-aatc' style={{ padding: '20px 0px 0px 0px', margin: '5px 3px' }}>
          {!isLoading &&
            <>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontSize: '12px' }}>
                <span>{t('estimated_delivery')}&nbsp;</span>{getDeliverydays()}
              </div>
            </>
          }
        </div>
      }

      {isMobcreen &&

        <>
          {tabbyCurrencies?.includes(userData?.contant?.currency) &&
            <div className='product-brand' style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '0px 3px' }}>
              <div style={{ fontSize: '13px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>Split in 4 payments of &nbsp;</span>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {productFetching ? <Skeleton variant="rect" style={{ height: '12px', width: '50px' }} /> :
                    <strong>
                      {formatPrice(Number(productData?.prices?.value) / 4, productData?.prices?.currency ? productData?.prices?.currency : userData?.contant?.currency, 0)}.&nbsp;</strong>
                  }
                </span>
                <span style={{ display: 'flex', alignItems: 'center' }}>No interest with &nbsp;</span>
                <span style={{ display: 'flex', alignItems: 'center' }}><img src='/tabby-logo-1.png' style={{ width: '40px' }} /></span>
              </div>
            </div>
          }
        </>
      }

      <div className="Page-card" style={{ margin: '0px 3px' }}>
        {thisFeatures?.length > 0 &&
          <div className='product-brand' style={{ fontSize: '14px', fontWeight: 500, color: '#38393a' }}>
            <br />
            {t('product_features')}:
          </div>
        }
        {isLoading ?
          <>
            <Skeleton variant="rect" height={'15px'} width={'60%'} />
            <Skeleton variant="rect" height={'15px'} width={'60%'} />
            <Skeleton variant="rect" height={'15px'} width={'40%'} />
          </>
          :
          <ul className="Page-card-header" style={{ padding: '0px 12px 0px 12px' }}>
            {thisFeatures?.length > 0 &&
              <>
                {thisFeatures?.slice(0, getFeaturesLength ? 100 : 2)?.map((item, i) =>
                  <li style={{ color: '#38393a', fontSize: '13px', wordBreak: 'auto-phrase' }} key={i}>
                    {item?.replace(/�/g, "'")}
                  </li>
                )}
                {thisFeatures?.length > 2 &&
                  <div
                    onClick={(() => setFeaturesLength(getFeaturesLength ? false : true))}
                    style={{ fontSize: '0.85rem', cursor: 'pointer', color: '#4e93d9', fontWeight: 400 }}>
                    {getFeaturesLength ? t('show_less') : t('show_more')}
                  </div>
                }
              </>
            }
          </ul>
        }
      </div>
    </div>
  );
}

export default ProductFeatures;
